import { Injectable, Injector } from '@angular/core';

import { ResetPasswordModel } from '../models/reset-password.model';
import { UserSignUpModel } from '../models/user-sign-up.model';
import { UserModel } from '../models/user.model';
import { AccountApiResource } from './api/account-api.resource';
import { BaseResource } from './base.resource';
import { AccountDbResource } from './db/account-db.resource';
import { UserLoginModel } from '../models/user-login.model';

@Injectable({
  providedIn: 'root',
})
export class AccountResource extends BaseResource<
  AccountDbResource,
  AccountApiResource
> {
  constructor(
    db: AccountDbResource,
    api: AccountApiResource,
    injector: Injector
  ) {
    super(db, api, injector);
  }

  getUser() {
    return this.api.getUser();
  }

  login(model: UserLoginModel) {
    return this.api.login(model);
  }

  loginViaProvider(
    provider: string,
    providerId,
    email: string,
    firstName: string,
    lastName: string,
    avatarUrl: string
  ) {
    return this.api.loginViaProvider(
      provider,
      providerId,
      email,
      firstName,
      lastName,
      avatarUrl
    );
  }

  logout() {
    return this.api.logout();
  }

  signUp(user: UserSignUpModel) {
    return this.api.signUp(user);
  }

  forgotPassword(email: string) {
    return this.api.forgotPassword(email);
  }

  resetPassword(userData: ResetPasswordModel) {
    return this.api.resetPassword(userData);
  }

  update(user: UserModel) {
    return this.api.update(user);
  }

  changeAvatar(imageUrl: string) {
    return this.api.changeAvatar(imageUrl);
  }

  suggestAccount() {
    return this.api.suggestAccount();
  }

  localUpdate(user: UserModel) {
    this.db.update(user);
  }

  deleteAccount() {
    return this.api.deleteAccount();
  }
}
