import { BaseModel } from './base.model';

export type UserType = 'users' | 'guests';

export class UserModel extends BaseModel {
  Id: string;
  Email: string;
  FirstName: string;
  LastName: string;
  Nickname: string;
  AvatarUrl: string;
  Password: string;
  NewPassword: string;
  NewPasswordConfirmation: string;
  BackofficeUserId: number;
  IsDeleted: boolean;
  IsKiosker: boolean;
  IsWebsiteLogin: boolean;
  MemberId: string;
  Roles: any[];
  UserName: string;
  Followed: boolean;
}
