import { Injector } from '@angular/core';
import _assignIn from 'lodash-es/assignIn';
import { Observable, of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { LoaderService } from './../services/loader.service';
import { ApiResourceInterface } from './api/api-resource.interface';
import { DbResourceInterface } from './db/db-resource.interface';

export class BaseResource<
  DbResourceType extends DbResourceInterface,
  ApiResourceType extends ApiResourceInterface
> {
  protected loader: LoaderService;

  constructor(
    protected db: DbResourceType,
    protected api: ApiResourceType,
    protected injector: Injector
  ) {
    this.loader = this.injector.get(LoaderService);
  }

  protected _get(
    apiCall: Observable<any>,
    dbCallResult: any,
    dbUpdateFn: (data: any) => any = null,
    showLoader = true
  ) {
    const result = dbCallResult;
    if (result) {
      return of(result);
    }
    return this._fetchAndComplete(apiCall, dbUpdateFn, showLoader);
  }

  protected _fetchAndComplete(
    apiCall: Observable<any>,
    dbUpdateFn: (data: any) => any,
    showLoader = true
  ) {
    if (showLoader) {
      this.loader.show();
    }

    return apiCall.pipe(
      tap((results) => {
        if (dbUpdateFn) {
          dbUpdateFn(results);
        } else {
          this.db.update(results);
        }
      }),
      finalize(() => {
        if (showLoader) {
          this.loader.hide();
        }
      })
    );
  }

  protected _completeWithoutCaching(
    apiCall: Observable<any>,
    showLoader = true
  ) {
    if (showLoader) {
      this.loader.show();
    }

    return apiCall.pipe(
      finalize(() => {
        if (showLoader) {
          this.loader.hide();
        }
      })
    );
  }

  protected _upsertWithDeferredApiCall(
    apiCall: Observable<any>,
    dbUpdateFn: (data: any) => any,
    data: any
  ) {
    const item = dbUpdateFn(data);

    apiCall.subscribe((apiRes) => {
      _assignIn(item, apiRes);

      if (dbUpdateFn) {
        dbUpdateFn(item);
      } else {
        this.db.update(item);
      }
    });

    return item;
  }

  protected _deleteWithDeferredApiCall(
    apiCall: Observable<any>,
    dbUpdateFn: (data: any) => any,
    data: any
  ) {
    const item = dbUpdateFn(data);
    apiCall.subscribe();
    return item;
  }
}
