import _map from 'lodash-es/map';
import _each from 'lodash-es/each';

export class BaseApiResource {
  _mapItem(modelClass, item) {
    return new modelClass(item);
  }

  _mapCollection(modelClass, items) {
    const ret = [];

    _each(items, (item) => {
      ret.push(new modelClass(item));
    });

    return ret;
  }

  _prepareStringAsPayload(text) {
    return '"' + ('' + text).replace(/"/g, '\\"') + '"';
  }

  _encodeQueryParams(params) {
    return _map(params, (value, key) => {
      return key + '=' + value;
    }).join('&');
  }
}
