import _clone from 'lodash-es/clone';
import _each from 'lodash-es/each';
import _isEmpty from 'lodash-es/isEmpty';

import { BaseModel } from '../../models/base.model';
import { StorageService } from '../../services/storage.service';
import { removeMethods } from '../../utils';
import { SubmodelMapperService } from './submodel-mapper.service';

export class BaseDbResource {
  protected model;

  constructor(
    protected storageService: StorageService,
    protected documentName: string,
    model: BaseModel,
    protected submodelMapperService: SubmodelMapperService,
    protected submodelDetails: Array<any>,
  ) {
    this.model = model;
  }

  get(loadSubmodels: boolean = true) {
    const doc = this.storageService.get(this.documentName);
    return doc && loadSubmodels ? this.loadSubmodels(doc) : doc;
  }

  update(data: any) {
    const dataToStore = _clone(data);
    this.submodelMapperService.processSubmodels(
      dataToStore,
      this.submodelDetails,
    );

    this._processValues(dataToStore);
    this.storageService.set(this.documentName, dataToStore);
    return dataToStore;
  }

  exists() {
    return this.storageService.has(this.documentName);
  }

  protected loadSubmodels(doc, loadSubmodels = true) {
    if (!loadSubmodels) {
      return doc;
    } else {
      this.submodelMapperService.preloadSubmodels(doc, this.submodelDetails);
      const d = this._cast(doc);
      this.submodelMapperService.clearPreloadedSubmodels();
      return d;
    }
  }

  private _cast(doc) {
    if (Array.isArray(doc)) {
      const ret = [];

      if (_isEmpty(doc)) {
        return ret;
      }

      for (const i of doc) {
        const d = this.submodelMapperService.loadSubmodels(
          i,
          this.submodelDetails,
        );
        ret.push(new this.model(d));
      }
      return ret;
    } else {
      const d = this.submodelMapperService.loadSubmodels(
        doc,
        this.submodelDetails,
      );
      return new this.model(d);
    }
  }

  private _processValues(data) {
    if (Array.isArray(data)) {
      _each(data, (item, index) => {
        data[index] = removeMethods(item);
      });
    } else {
      data = removeMethods(data);
    }
  }
}
