import { Injectable, Injector } from '@angular/core';
import _each from 'lodash-es/each';
import _find from 'lodash-es/find';
import _head from 'lodash-es/head';
import _isEmpty from 'lodash-es/isEmpty';
import _without from 'lodash-es/without';

import { throwError } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class SubmodelMapperService {
  preloadedSubmodels = {};

  constructor(private injector: Injector) {}

  loadSubmodels(model, submodelDetails) {
    if (!model._submodels) {
      return model;
    }

    for (const submodelData of model._submodels) {
      const submodelMap = _find(submodelDetails, {
        resource: submodelData.resource,
      });

      if (submodelMap) {
        const collection = this._getSubmodels(submodelData.data, submodelMap);
        this._attachSubmodelToModel(model, collection, submodelMap);
      }
    }

    return model;
  }

  processSubmodels(model, submodelDetails) {
    if (!submodelDetails.length) {
      return {};
    }

    this._storeSubmodels(model, submodelDetails);

    if (Array.isArray(model)) {
      _each(model, (item) => {
        this._transformSubmodelsToIds(item, submodelDetails);
      });
    } else {
      this._transformSubmodelsToIds(model, submodelDetails);
    }

    return {};
  }

  private _storeSubmodels(model, submodelDetails) {
    for (const details of submodelDetails) {
      const dbResource = this.injector.get(details.dbResource);
      let submodels = [];

      if (!details.isCollection) {
        if (Array.isArray(model)) {
          _each(model, (item) => {
            submodels.push(this._getSubmodelFromModel(item, details));
          });
        } else {
          submodels.push(this._getSubmodelFromModel(model, details));
        }
      } else {
        if (Array.isArray(model)) {
          _each(model, (item) => {
            _each(this._getSubmodelFromModel(item, details), (submodel) => {
              submodels.push(submodel);
            });
          });
        } else {
          _each(this._getSubmodelFromModel(model, details), (submodel) => {
            submodels.push(submodel);
          });
        }
      }

      submodels = _without(submodels, null);

      if (submodels.length) {
        const updateFn = dbResource.updateCollection();
        updateFn(submodels);
      }
    }
  }

  private _getSubmodels(submodelData, submodelMap) {
    const collection = [];

    for (const id of submodelData) {
      const submodel = this._getSubmodel(submodelMap, id);

      if (submodel) {
        collection.push(submodel);
      } else {
        throwError(`Submodel not found: ${submodelMap.dbResource} - ${id}`);
      }
    }
    return collection;
  }

  private _getSubmodel(submodelMap, id) {
    const submodelData = _find(
      this.preloadedSubmodels[submodelMap.dbResource],
      { Id: id },
    );
    return submodelData
      ? submodelData
      : this.injector.get(submodelMap.resource).get(id, false);
  }

  /**
   * Preload submodels in order to prevent loading submodels for every item in models collection
   */
  preloadSubmodels(doc, submodelDetails) {
    if (Array.isArray(doc)) {
      doc = _head(doc);
    }

    if (_isEmpty(doc) || _isEmpty(doc._submodels)) {
      return;
    }

    for (const submodelData of doc._submodels) {
      const submodelMap = _find(submodelDetails, {
        resource: submodelData.resource,
      }) as any;

      if (submodelMap) {
        const collection = this.injector.get(submodelMap.dbResource).get(false);
        this.preloadedSubmodels[submodelMap.dbResource] = collection;
      } else {
        // this can be the case if some submodel is deleted meanwhile from resource but still exists in db
        // so just skip this one
        this.preloadedSubmodels[submodelData.resource] = [];
      }
    }
  }

  clearPreloadedSubmodels() {
    this.preloadedSubmodels = {};
  }

  private _transformSubmodelsToIds(model, submodelDetails) {
    const submodels = [];

    _each(submodelDetails, (details) => {
      const ids = [];

      if (details.isCollection) {
        _each(this._getSubmodelFromModel(model, details), (submodel) => {
          ids.push(submodel.Id);
        });
      } else {
        ids.push(this._getSubmodelFromModel(model, details).Id);
      }

      submodels.push({
        data: ids,
        resource: details.resource,
      });
    });

    model._submodels = submodels;
  }

  private _getSubmodelFromModel(model, submodelMap) {
    if (typeof submodelMap.get === 'function') {
      return submodelMap.get(model);
    } else {
      return model[submodelMap.get];
    }
  }

  private _attachSubmodelToModel(model, collection, submodelMap) {
    const data = submodelMap.isCollection ? collection : _head(collection);

    if (typeof submodelMap.set === 'function') {
      submodelMap.set(model, data);
    } else {
      model[submodelMap.set] = data;
    }
  }
}
