import { Injectable } from '@angular/core';
import { finalize, Observer, tap } from 'rxjs';

import { UserModel } from '../models/user.model';
import { AccountResource } from '../resources/account.resource';

@Injectable({
  providedIn: 'root',
})
export class ActiveUserService {
  private activeUser: UserModel = {} as UserModel;
  private loadingInProgress = false;

  constructor(private accountResource: AccountResource) {}

  loadUser() {
    this.loadingInProgress = true;
    return this.accountResource.getUser().pipe(
      tap((res: UserModel) => {
        // keeping this legacy copy as we don't know if the object is mutatated somewhere else
        this.copyUser(this.activeUser, res);
      }),
      finalize(() => {
        this.loadingInProgress = false;
      })
    );
  }

  setActiveUser(user: UserModel) {
    this.copyUser(this.activeUser, user);
    this.accountResource.localUpdate(this.activeUser);
  }

  isAuthenticated() {
    return this.activeUser && this.activeUser.Id;
  }

  user() {
    return this.activeUser;
  }

  update(data) {
    this.copyUser(this.activeUser, data, false);
    this.accountResource.localUpdate(this.activeUser);

    return this.activeUser;
  }

  getID() {
    return this.isAuthenticated() ? this.activeUser.Id : null;
  }

  isGuest() {
    return this.getID() === null;
  }

  private waitForUserToLoad(o: Observer<any>) {
    if (!this.loadingInProgress) {
      o.next(this.activeUser);
      o.complete();
    } else {
      setTimeout(() => {
        this.waitForUserToLoad(o);
      }, 100);
    }
  }

  private copyUser(target: UserModel, source: UserModel, clean = true) {
    if (clean) {
      for (const key of Object.keys(target)) {
        delete target[key];
      }
    }
    Object.assign(target, source);
  }
}
