import { Injectable } from '@angular/core';
import _each from 'lodash-es/each';
import _some from 'lodash-es/some';
import _includes from 'lodash-es/includes';
import _isEmpty from 'lodash-es/isEmpty';

// Currently no real storage is used. Data is stored in object and used just in run-time
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage = {};

  constructor() {
    // setInterval(() => console.log('ee', this.storage), 2000);
  }

  get(id: string) {
    return this.storage[id];
  }

  has(id: string) {
    return this.storage[id];
  }

  set(id: string, data: any) {
    this.storage[id] = data;
    return data;
  }

  clear() {
    this.storage = {};
  }

  forEach(callback) {
    return _each(this.storage, callback);
  }

  hasGuestData() {
    const relevantKeys = [
      'humidors',
      'product-shapes',
      'products',
      'user-journal',
      'user-wish-list',
      'user-favorites',
    ];

    return _some(
      this.storage,
      (value, key) => _includes(relevantKeys, key) && !_isEmpty(value)
    );
  }
}
