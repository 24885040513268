import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResetPasswordModel } from '@shared/models/reset-password.model';
import _pick from 'lodash-es/pick';
import { Observable, Observer, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { LoginResultModel } from '../../models/login-result.model';
import { SuggestAccountModel } from '../../models/suggest-account.model';
import { UserLoginModel } from '../../models/user-login.model';
import { UserSignUpModel } from '../../models/user-sign-up.model';
import { UserModel } from '../../models/user.model';
import { ApiResourceInterface } from './api-resource.interface';
import { BaseApiResource } from './base-api.resource';

@Injectable({
  providedIn: 'root',
})
export class AccountApiResource
  extends BaseApiResource
  implements ApiResourceInterface
{
  constructor(private http: HttpClient) {
    super();
  }

  getUser() {
    return this.http.get('account').pipe(
      map((res) => new UserModel(res)),
      catchError(() => of(new UserModel({})))
    );
  }

  login(model: UserLoginModel) {
    let params = new HttpParams();
    params = params.set('isWebsiteAccount', model.IsWebsiteAccount.toString());

    return this.http.post<LoginResultModel>(
      'account/login',
      _pick(model, ['Email', 'Password']),
      { params: params }
    );
  }

  loginViaProvider(
    provider: string,
    providerId,
    email: string,
    firstName: string,
    lastName: string,
    avatarUrl: string
  ) {
    return this.http.post<LoginResultModel>(`account/login/${provider}`, {
      Id: providerId,
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      AvatarUrl: avatarUrl,
    });
  }

  logout() {
    return new Observable((o: Observer<any>) => {
      return this.http.post('account/logout', {}).subscribe(
        (res) => {
          o.next(res);
          return o.complete();
        },
        () => {
          o.next({});
          return o.complete();
        }
      );
    });
  }

  signUp(user: UserSignUpModel) {
    return this.http.post<LoginResultModel>('account/register', user);
  }

  forgotPassword(email: string) {
    return this.http.post('account/forgotpassword', { Email: email });
  }

  resetPassword(userData: ResetPasswordModel) {
    return this.http.post('account/resetpassword', userData);
  }

  update(user: UserModel) {
    return this.http.post('account/update', user);
  }

  changeAvatar(imageUrl?: string) {
    return this.http.post(
      'account/avatar',
      imageUrl ? this._prepareStringAsPayload(imageUrl) : {},
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      }
    );
  }

  suggestAccount() {
    return this.http.get<SuggestAccountModel>('account/suggest');
  }

  deleteAccount() {
    return this.http.delete('account?confirm=true');
  }
}
